// importa os plugins de Request para fazer as requisições para o back
import API from '../plugins/api'

// exporta o store do bot
export default {
    state: {
        // estado do store do bot
        status: null,
        // registro dos bots
        bot: null,
    }, 
    // funções para modificar o estado do store de bots
    mutations: {
        // função para setar o status do store de bots
        SET_STATUS(state, status){
            // seta o status do store de bots
            state.status = status
        },
        // função para setar os bots
        SET_BOTS(state, bot){
            // seta os registros de bots
            state.bot = {
                // seta os regitsros de bots
                dados: bot.bots,
                // seta o total de registros de bots
                total: bot.total ? bot.total : (bot.bots ? bot.bots.length : 0)
            }
        },
    },
    // funções para as ações do store de bots
    actions: {
        /**
         * função para coletar os registros de bots do back
         * @param {*} param0
         * @param {*} filters
         */
        async getBots({ commit }, filters){
            // seta o status de store de bots para loading
            await commit('SET_STATUS', 'loading')
            // faz a requisição para o back
            const { data, status } = await API.get(
                // url da rota do back para fazer a requisição
                '/bots' +
                // por qual coluna devem ser ordenados os registros
                (filters.order_column ? ('?orderBy=' + filters.order_column) : '?orderBy=uuid') +
                // qual a ordem que deve ser ordenado (ASC ou DESC)
                (filters.order_dir ? '&order=' + filters.order_dir : '&order=asc') +
                // qual a quantidade de dados
                (filters.limit ? '&limit=' + filters.limit : '') +
                // qual a página
                (filters.page ? '&page=' + filters.page + '&_embed=false' : '&_embed=true') +
                // os registros que contém a busca solicitada
                (filters.search ? '&search=' + filters.search : ''),
            )
            // seta o status do store de bots para vazio
            await commit('SET_STATUS', '')
            // caso o status seja 200 (requisição foi concluída)
            if(status == 200){
                // seta os dados no store
                await commit('SET_BOTS', filters.page ? { bots: data.data, total: data.meta.total } : data)
            }
            // retorna a resposta da requisição
            return data
        },
        /**
         * Função para coletar os dados de um bot do back
         * @param {*} param0
         * @param {*} bot_uuid
         */
        async getBot({ commit }, bot_uuid){
            // seta o status do store de bots para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/bots/' + bot_uuid)
            // seta o status do store de bots para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * Função para adicionar dados de um bot
         * @param {*} param0
         * @param {*} dados_bot
         */
        async postBot({ commit }, dados_bot){
            // seta o status do store de bots para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.post('/bots', dados_bot)
            // seta o status do store de bots para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * Função para editar os dados de uma agenda
         * @param {*} param0
         * @param {*} date_update
         */
        async putBot({ commit }, date_update){
            // seta o status do store de bots para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.put('/bots/' + date_update.uuid, date_update.dados_bot)
            // seta o status de bots para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * Função para pegar os flows para o bot
         * @param {*} param0
         */
        async getFlows({ commit }){
            // seta o status do store de bots para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/botsFlow')
            // seta o status do store de bots para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
    },
    // funções para coletar o estado do store de bots
    getters: {
        // coleta o status do store de bots
        status_bots: state => state.status,
        // coleta os registros do store de bots
        bot: state => state.bot,
    }
}






