// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
import API from '../plugins/api'

// eporta o store de usuários
export default {
	// estado do store de usuários
	state: {
		// status do store de usuários
		status: null,
		// registro dos usuários
		usuarios: null,
		//registro de companies
		companies:null,
		notificacoes: [],
		title_original: document.title,
		menus_usuario:[]
	},
	// funções para modificar o estado do store de usuários
	mutations: {
		// função para setar o status do store de usuários
		SET_STATUS(state, status) {
			// seta o status do store de usuários
			state.status = status
		},
		// função para setar os registros de usuários
		SET_USUARIOS(state, usuarios) {
			// seta os registros de usuários
			state.usuarios = {
				// seta os registros de usuários
				dados: usuarios.users,
				// seta o total registros de usuários
				total: usuarios.total ? usuarios.total : (usuarios.users ? usuarios.users.length : 0)
			}
		},
		// função para setar os registros de usuários
		SET_MENUS(state, menus_usuario) {
			// seta os registros de usuários
			state.menus_usuario = menus_usuario
		},
		// função para setar os registros de usuários
		SET_QUANTIDADE_NOTIFICACAO(state,notificacoes) {
			state.notificacoes = notificacoes;
			document.title = (state.notificacoes.length > 0 ? '(' + state.notificacoes.length + ') ' : '') +  state.title_original;
		},
	},
	// funções para as ações do store de usuários
	actions: {
		/**
		 * função para coletar os usuários do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getUsuarios({ commit }, filters) {
			// seta o status do store de usuários para loading
			await commit('SET_STATUS', 'loading')
				// faz a requisição para o back
				const { data, status } = await API.get(
					// url da rota do back para fazer a requisição
					'/user' + 
					// por qual coluna devem ser ordenados os registros
					(filters.order_column ? ('?orderBy=' + filters.order_column) : '?orderBy=uuid') +
					// qual a ordem que deve ser ordenado (ASC ou DESC)
					(filters.order_dir ? '&order=' + filters.order_dir : '&order=asc') +
					// por qual empresa os registros devem ser filtrados
					(filters.adicionais.company_uuid ? '&company_uuid=' + filters.adicionais.company_uuid : '') + 
					// qual a quantidade de dados
					(filters.limit ? '&limit=' + filters.limit : '') +
					// qual a página
					(filters.page ? '&page=' + filters.page + '&_embed=false' : '&_embed=true') +
					// os registros que contem a busca solicitada
					(filters.search ? '&search=' + filters.search : ''), 
				)
				// seta o status do store de empresa para vazio 
				await commit('SET_STATUS', '')
				// caso o status seja 200 (requisiçao foi concluida) 
				if (status == 200) {
					// seta os dados no store
					await commit('SET_USUARIOS', filters.page ? { users: data.users.data, total: data.users.meta.total } : data)
				}
				// retorna a resposta da requisição
				return data
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} usuario_uuid 
		 */
		async getUsuario({ commit },usuario_uuid) {

			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get('/user/' + usuario_uuid)
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async getUsuarioSelect({ commit }) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get(
				// url da rota do back para fazer a requisição
				'/user?orderBy=name&order=asc&_embed=true')
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async getEmpresasSelect({ commit }) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get(
				// url da rota do back para fazer a requisição
				'/selectEmpresas')
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async getClientsSelect({ commit },dados) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get(
				// url da rota do back para fazer a requisição
				'/client?orderBy=name&order=asc&_embed=true&search=' + dados.q)
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		
		// /**
		//  * função para criar ou editar 1 usuário
		//  * @param {*} param0 
		//  * @param {*} dados_usuario 
		//  */
		//  async postUsuarioIdOneSignal({ commit }, dados_usuario) {
		// 	// seta o status do store de usuarios para loading
		// 	await commit('SET_STATUS', 'loading')
		// 	// faz uma requisição para o back e coleta o resultado na variavel resp
		// 	const resp = await API.post(
		// 		// url da rota do back para fazer a requisição
		// 		'/device',
		// 		// corpo da requisição
		// 		dados_usuario
		// 	)
		// 	// seta o status do store de usuario para vazio 
		// 	await commit('SET_STATUS', '')
		// 	// retorna a resposta da requisição
		// 	return resp
		// },
		
		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_usuario 
		 */
		async postUsuarios({ commit }, dados_usuario) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.post('/user', dados_usuario)
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		/**
		 * função para editar um usuário
		 * @param {*} param0 
		 * @param {*} date_update 
		 */
		async putUser({ commit }, date_update) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.put('/user/' + date_update.uuid, date_update.dados_usuario)
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		
		/**
		 * função para buscar menu que o usuario pode ver
		 * @param {*} param0 
		 */
		async getMenuByUsuario({ commit }) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/usuario/getMenus/',
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp.status == '200' ? resp.data.menus : []
		},
		
		/**
		 * função para buscar menu que o usuario pode ver
		 * @param {*} param0 
		 */
		async getNotificacoesUsuario({ commit },dados) {

			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/usuario/get_notificacoes/?' +
				(dados ? '&dados=' + JSON.stringify(dados) : '')
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição

			await commit('SET_QUANTIDADE_NOTIFICACAO', resp.status == '200' ? resp.data : [])
		},
		
		/**
		 * função para buscar menu que o usuario pode ver
		 * @param {*} param0 
		 */
		async visualizarNotificacao({ commit },dados) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/usuario/visualizar_notificacao/',
				// corpo da requisição
				body: dados, 
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			await commit('SET_QUANTIDADE_NOTIFICACAO', resp.status == '200' ? resp.data : [])
		},
		
		/**
		 * função para buscar menu que o usuario pode ver
		 * @param {*} param0 
		 */
		async verificaMenuByUsuario({ commit },menu) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/usuario/verificaMenu/',
				// corpo da requisição
				body: {menu:menu}, 
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp.status == '200' ? resp.data.sucesso : false
		},
	},
	// funções para coletar o estado do store de usuários
	getters: {
		// coleta o status do store de usuários
		status_usuarios: state => state.status,
		// coleta os registros do store de usuários
		usuarios: state => state.usuarios,
		notificacoes: state => state.notificacoes,
		
	}
}