// importa o plugin da API para fazer as requisições para o back
import API from '../plugins/api'


// exporta o store da mensagem
export default {
	// estado do store da mensagem
	state: {
		// status do store da mensagem
		status: null,
		// registro das mensagem
		mensagens: null,
	},
	// funções para modificar o estado do store da mensagem
	mutations: {
		// função para setar o status do store da mensagem
		SET_STATUS(state, status) {
			// seta o status do store da mensagem
			state.status = status
		},
		// função para setar os registros das mensagem
		SET_RESPOSTAS(state, mensagens) {
			// seta os registros da mensagem
			state.mensagens = {
				// seta os registros das mensagem
				dados: mensagens.answers,
				// seta o total registros das mensagem
				total: mensagens.total ? mensagens.total : (mensagens.answers ? mensagens.answers.length : 0)
			}
		},
	},
	// funções para as ações do store das mensagem
	actions: {
		/**
		 * função para coletar os registros das mensagem do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getRespostas({ commit }, filters) {
			// seta o status do store da mensagem para loading
			await commit('SET_STATUS', 'loading')
			// faz a requisição para o back
			const { data, status } = await API.get(
				// url da rota do back para fazer a requisição
				'/message' + 
				// por qual coluna devem ser ordenados os registros
				(filters.order_column ? ('?orderBy=' + filters.order_column) : '?orderBy=positive_answers.uuid') +
				// qual a ordem que deve ser ordenado (ASC ou DESC)
				(filters.order_dir ? '&order=' + filters.order_dir : '&order=asc') +
				// qual a quantidade de dados
				(filters.limit ? '&limit=' + filters.limit : '') +
				// qual a página
				(filters.page ? '&page=' + filters.page + '&_embed=false' : '&_embed=true') +
				// os registros que contem a busca solicitada
				(filters.search ? '&search=' + filters.search : ''), 
			)
			// seta o status para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (status == 200) {
				// seta os dados no store
				await commit('SET_RESPOSTAS', filters.page ? { answers: data.data, total: data.meta.total } : data)
			}
			// retorna a resposta da requisição
			return data
		},

		/**
		 * função para coletar apenas uma mensagem do back
		 * @param {*} param0 
		 * @param {*} resposta_uuid 
		 */
		async getResposta({ commit }, resposta_uuid) {
			// seta o status do store da mensagem para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get('/message/' + resposta_uuid)
			// seta o status do store da mensagem para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		
		/**
		 * função para criar uma mensagem
		 * @param {*} param0 
		 * @param {*} dados_resposta 
		 */
		async postResposta({ commit }, dados_resposta) {
			// seta o status do store da mensagem para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.post('/message', dados_resposta)
			// seta o status do store para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		// função para atualizar uma mensagem
		async putResposta({ commit }, date_update) {
			// seta o status do store da mensagem para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.put('/message/' + date_update.uuid, date_update.dados_resposta)
			// seta o status do store da mensagem para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
	},
	// funções para coletar o estado do store da mensagem
	getters: {
		// coleta o status do store da mensagem
		status_mensagens: state => state.status,
		// coleta os registros do store da mensagem
		mensagens: state => state.mensagens,
	}
}