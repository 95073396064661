import Vue from 'vue'
import Vuex from 'vuex'

import config from "@/store/config.js"
import login from "@/store/login.js"
import usuarios from "@/store/usuarios.js"
import empresas from "@/store/empresas.js"
import bots from "@/store/bots.js"
import application from "@/store/application.js"
import theme from "@/store/theme.js"
import listas from "@/store/transmissoes.js"
import mensagens from "@/store/respostas_positivas.js"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		config,
		login,
		usuarios,
		empresas,
		bots,
		application,
		theme,
		listas,
		mensagens,
	},
})