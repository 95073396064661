import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
//import API from '../plugins/api'

Vue.use(VueRouter)

function verificaLogin(to, from, next) {
	if (store.getters.isLoggedIn) {
		store.dispatch('setUserID').then(function () {
			if (store.getters.user_uuid) {
				next()
				return
			} else {
				next('/login')
			}
		})
	} else {
		next('/login')
	}
}

// async function verificaGroup(to, from, next, requisito) {
// 	if (store.getters.isLoggedIn) {
// 		const { data, status } = await API.get('user-group?orderBy=id&order=asc&_embed=true')
// 		if(status == 200){
// 			if(((data.user_groups).find(element => element.reference_id <= requisito)) == undefined){
// 				next('/sem-permissao')
// 			// }else if (store.getters.workspace_id == null ){
// 			// 	next('/')
// 			}else{
// 				next()
// 			}
// 		}else{
// 			next('/')
// 		}
// 	} else {
// 		next('/login')
// 	}
// }


 function verificaGroup(to, from, next, requisitos) {
	if (store.getters.isLoggedIn) {
		if (requisitos.some(requisito => requisito == store.getters.user.group.key)){
			next()
		}else{
			next('/sem-permissao')
		}
	} else {
		next('/login')
	}
}


function notLogin(to, from, next) {
	if (!store.getters.isLoggedIn) {
		next()
		return
	} else {
		next('/')
	}
}

function notLoginRecover(to, from, next) {
	if (!store.getters.isLoggedIn && localStorage.getItem('recover_token')) {
		next()
		return
	} else {
		next('/recuperar-senha')
	}
}

function deslogar(to, from, next) {
	store.dispatch('logout').then(function () {
		next('/login')
	})
}

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import('@/views/Dashboard.vue'),
		beforeEnter(to, from, next) {
			verificaLogin(to, from, next)
		},
		children: [
			{
				path: '/dashboard',
				name: 'Pages Dashboard',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, ['super', 'workspace-admin', 'company-admin'])
				},
				component: () => import('@/views/pages/PageDashboard.vue'),
			},
			{
				path: '/usuarios',
				name: 'Usuários',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, ['super', 'workspace-admin', 'company-admin'])
				},
				component: () => import('@/views/pages/Usuarios.vue'),
			},
			{
				path: '/empresas',
				name: 'Empresas',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, ['super', 'workspace-admin'])
				},
				component: () => import('@/views/pages/Empresas.vue'),
			},
			{
				path: '/bots',
				name: 'Bots',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, ['super', 'workspace-admin', 'company-admin', 'company-agent'])
				},
				component: () => import('@/views/pages/Bots.vue'),
			},
			{
				path: '/transmissoes',
				name: 'Transmissoes',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, ['company-agent','company-admin'])
				},
				component: () => import('@/views/pages/Transmissoes.vue'),
			},
			{
				path: '/respostas_positivas',
				name: 'Respostas',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, ['super', 'workspace-admin', 'company-admin', 'company-agent'])
				},
				component: () => import('@/views/pages/RespostasPositivas.vue'),
			},
			{
				path: '/sem-permissao',
				name: 'Sem Permissão',
				component: () => import('@/views/pages/SemPermissao.vue'),
			},
		]
	},
	{
		path: '/logout',
		beforeEnter(to, from, next) {
			deslogar(to, from, next)
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/Login.vue'),
		beforeEnter(to, from, next) {
			notLogin(to, from, next)
		}
	},
	{
		path: '/recuperar-senha',
		name: 'Esqueci minha senha',
		component: () => import('@/views/login/RecuperarSenha.vue'),
		beforeEnter(to, from, next) {
			notLogin(to, from, next)
		}
	},
	{
		path: '/validar-codigo',
		name: 'Validar código',
		component: () => import('@/views/login/RecuperarSenhaCodigo.vue'),
		beforeEnter(to, from, next) {
			notLoginRecover(to, from, next)
		}
	},
	{
		path: '/nova-senha',
		name: 'Nova senha',
		component: () => import('@/views/login/NovaSenha.vue'),
		beforeEnter(to, from, next) {
			notLoginRecover(to, from, next)
		}
	},
	{
		path: '/privacidade',
		name: 'Políticas de privacidade',
		component: () => import('@/views/PoliticasDePrivacidade.vue'),
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/pages/404.vue'),
	},
]

export default new VueRouter({
	routes,
	linkExactActiveClass: 'active',
	mode: 'history'
})