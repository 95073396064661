// importa os plugins da API para fazer as requisições para back
import API from "../plugins/api"

// exporta o store das listas de transmissão
export default {
    // estado do store de transmissões
    state: {
        // status do store das listas de transmissão
        status: null,
        // registro das lista de transmissão
        listas: null,
    },
    // funções para modficar o estado do store de transmissões
    mutations: {
        // função para setar o status do store de transmissões
        SET_STATUS(state, status){
            // seta o status do store de transmissões
            state.status = status
        },
        // função para setar os registros de transmissão
        SET_TRANSMISSOES(state, listas) {
            // seta os registros de transmissão
            state.listas = {
                // seta os registros de transmissão
                dados: listas.list_people,
                // seta o total de registros de transmissão
                total: listas.total ? listas.total : (listas.list_people ? listas.list_people.length : 0)
            }
        },
    },
    // funções para as ações do store de transmissões
    actions: {
        /**
         * função para coletar a listagem de transmissões do back
         * @param {*} param0
         * @param {*} filters
         */
        async getTransmissoes({ commit }, filters){
            // seta o status do store de transmissões para loading
            await commit('SET_STATUS', 'loading')
            // faz a requisição para o back
            const { data, status } = await API.get(
                // url da rota do back para fazer a requisição
                '/lists' +
                // por qual coluna devem ser ordenadas as transmissões
                (filters.order_column ? ('?orderBy=' + filters.order_column) : '?orderBy=uuid') +
                // qual a ordem que deve ser ordenado (ASC ou DESC)
                (filters.order_dir ? '&order=' + filters.order_dir : '&order=asc') +
                // qual a quantidade de dados
                (filters.limit ? '&limit=' + filters.limit : '') + 
                // qual a página
                (filters.page ? '&page=' + filters.page + '&_embed=false' : '&_embed=true') +
                // os registros que contém a busca solicitada
                (filters.search ? '&search=' + filters.search : '') +
                // qual o número desejado
                (filters.adicionais.numero ? '&number=' + filters.adicionais.numero : '') +
                // qual a identificação do usuário desejado
                (filters.adicionais.identificacao ? ('&identificacao=' + filters.adicionais.identificacao) : '') +
                // qual o estágio que deseja
                (filters.adicionais.estagio ? ('&stage=' + filters.adicionais.estagio) : ''),
            )
            // seta o status do store de transmissões para vazio
            await commit('SET_STATUS', '')
            // caso o status seja 200 (requisição foi concluída)
            if(status == 200){
                // seta os dados no store
                await commit('SET_TRANSMISSOES', filters.page ? { list_people: data.data, total: data.meta.total } : data)
            }
            // retorna a resposta da requeisição
            return data 
        },
        /**
         * função para coletar os dados de um usuário presente na lista
         * @param {*} param0
         * @param {*} numero_usuario
         */
        async getDados({ commit }, numero_usuario){
            // seta o status do store de transmissões para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/lists/' + numero_usuario)
            // seta o status do store de transmissões para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * função para coletar os estágios para o filtro
         * @param {*} param0
         */ 
        async getStages({ commit }){
            // seta o status do store de transmissões para loading
            await commit('SET_STATUS', 'loading')
            // faz a requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/getStages')
            // seta o status de transmissões para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * função para postar uma lista
         * @param {*} param0
         * @param {*} dados_lista
         */
        async postLista({ commit }, dados_lista){
            // seta o status do store de transmissões para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.post('/lists', dados_lista)
            // seta o status do store para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * Função para coletar os bots passando a empresa
         * @param {*} param0
         * @param {*} company_uuid
         */
        async botByCompany({ commit }, company_uuid){
            // seta o status do store de transmissões para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/botByCompany/' + company_uuid)
            // seta o status do store de transmissões para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
    },
    // funções para coletar o estado do store de transmissões
    getters: {
        // coleta o status do store de transmissões
        status_transmissoes: state => state.status,
        // coleta os registros do store de listas de transmissão
        listas: state => state.listas,
    }
}
